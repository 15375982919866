.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  color: #fff;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
}
.button:hover,
.button:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
.button:before {
  display: none;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1025px) {
  a.togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  float: right;
  margin-top: 32px;
}
div.sub1 > .item {
  margin-right: 26px;
}
div.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 > .item > .menu {
  font-size: 17px;
  line-height: 1.29411765;
  text-transform: uppercase;
  color: #711C2F;
  position: relative;
  letter-spacing: 0.6px;
}
div.sub1 > .item > .menu:before {
  display: block;
  width: 0;
  transition: all 0.3s;
}
div.sub1 > .item > .menu:hover:before,
div.sub1 > .item > .menu:focus:before,
div.sub1 > .item > .menu.path:before {
  display: block;
  width: 100%;
  background-color: #711C2F;
}
.desk {
  border-width: 0 86px;
}
@media (max-width: 1519px) {
  .desk {
    border-width: 0 60px;
  }
}
#head {
  margin-bottom: -8px;
}
.section--red {
  margin-top: 70px;
}
.section--three .base {
  margin-top: 16px;
  margin-bottom: 16px;
}
.section--four .area .unit.slim {
  margin-bottom: 32px;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.62037037%;
  margin-left: 1.62037037%;
  width: 96.75925926%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 46.75925926%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 103.3492823%;
  margin-left: -1.67464115%;
}
.section--three .side {
  width: 728px;
  max-width: calc(100% - 278px);
}
.section--three .base {
  float: right;
  width: 250px;
}
.section--one .area .pure.wide .part.tiny {
  width: 48.32535885%;
}
.section--one .area .flat {
  width: 96.75925926%;
}
.section--one .area .flat .pict.tall:first-child {
  width: 48.32535885%;
}
.section--one .area .flat.wide .body {
  padding-left: 51.67464115%;
}
.section--one .area .flat.slim .body {
  padding-right: 51.67464115%;
}
/*# sourceMappingURL=./screen-large.css.map */